import { reactive, Ref, ref } from '@vue/composition-api';
import {
  getRoles,
  getRole,
  createRole,
  editRole,
  deleteRole,
  checkIsDefaultRole,
  defaultRoleSettings,
  roleSettingsData,
  RoleProps,
  RolesItem,
  RoleItem,
  RoleItemRoleSettings,
} from '@/admin/roles';

class Roles {
  private _isLoading = ref(true);
  private _roles: Ref<RolesItem[]> = ref([]);

  get isLoading() {
    return this._isLoading.value;
  }
  get roles() {
    return this._roles.value;
  }

  constructor() {
    this.getRoles();
  }

  getRoles = async () => {
    this._isLoading.value = true;
    await getRoles()
      .then((roles) => {
        this._roles.value = roles
          .sort((a, b) => a.createDate - b.createDate)
          .map((role) => ({
            roleId: role.roleId,
            roleName: role.roleName,
            isDefault: !checkIsDefaultRole(role.roleId),
            isInUse: role.isInUse,
          }));
      })
      .finally(() => (this._isLoading.value = false));
  };

  deleteRole = async (roleId: string) => {
    await deleteRole(roleId);
  };
}

class Role {
  isLoading = true;
  item: RoleItem = {
    roleId: '',
    roleName: '',
    roleSettings: [],
  };

  constructor(roleId?: string) {
    if (roleId) this.item.roleId = roleId;
    this.init();
  }

  init = async () => {
    this.isLoading = true;
    if (this.item.roleId) {
      await this.getRole();
    } else {
      this.item.roleSettings = this.formatRoleSettings();
    }
    this.isLoading = false;
  };

  formatRoleSettings = (
    settings: { [key: string]: { [key: string]: boolean } } = defaultRoleSettings
  ): RoleItemRoleSettings[] => {
    const contents = Object.keys(settings);

    // 整形、設定対象外とするフラグをフィルターする
    const dataFormated: RoleItemRoleSettings[] = contents
      .map((content) => {
        const data = roleSettingsData[content];
        if (!data)
          return {
            contentName: '',
            contentLabel: '',
            isInactive: true,
            settings: [],
          };
        else
          return {
            contentName: content,
            contentLabel: data.contentName,
            isInactive: data.isInactive,
            settings: Object.keys(data.settings)
              .map((item) => ({
                flagName: item,
                flagLabel: data.settings[item].label || item,
                caption: data.settings[item].caption || '',
                value: settings[content][item],
                isInactive: data.settings[item].isInactive,
              }))
              .filter((setting) => !setting.isInactive),
          };
      })
      .filter((item) => !item.isInactive);

    return dataFormated;
  };

  getRole = async () => {
    const _item = await getRole(this.item.roleId);
    this.item = {
      roleId: _item.roleId,
      roleName: _item.roleName,
      roleSettings: this.formatRoleSettings(_item.roleSettings),
    };
  };

  createProps = () => {
    if (!this.item.roleName) {
      throw '権限名は必須です';
    } else {
      const props: RoleProps = {
        roleName: this.item.roleName,
        roleSettings: {},
      };
      this.item.roleSettings.forEach((item) => {
        const settings: { [key: string]: boolean } = {};

        item.settings.forEach((setting) => {
          settings[setting.flagName] = setting.value;
        });

        props.roleSettings[item.contentName] = settings;
      });
      return props;
    }
  };

  saveRole = async () => {
    const props = this.createProps();
    if (!props) return;
    if (this.item.roleId) {
      // 編集
      await editRole(this.item.roleId, props);
    } else {
      // 新規
      await createRole(props);
    }
  };

  checkIsDefaultRole = () => {
    return checkIsDefaultRole(this.item.roleId);
  };
}

export const useRoles = () => {
  const roles = new Roles();
  return roles;
};

export const useRole = (roleId: string) => {
  const role = new Role(roleId);
  return { role: reactive(role) };
};
