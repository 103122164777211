



































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useRole } from '@/composition/roles';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'Role',
  components: {
    FcRoleLoading,
  },
  props: {
    roleId: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('roles'));

    const { role } = useRole(props.roleId);
    const notification = useNotification();
    const { confirmDialog } = useConfirm();

    const isSaving = ref(false);

    const saveRole = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        await role.saveRole();
        if (props.roleId) {
          notification.notify('変更しました。');
          // 自分のロールを再取得
          if (props.roleId === myAttributes.roleId) myAttributes.getMyRole();
        } else {
          notification.notify('作成しました。');
          context.root.$router.push('/roles');
        }
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };

    return {
      pageTitle: '管理者権限',
      myRoleSettings,
      role,
      isSaving,
      saveRole,
    };
  },
});
